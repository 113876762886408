.dashboard-main {
    width: 100%;
    min-height: 100%; }

.dashboard-content {
    //margin and width to offset the fixed position side menu
    margin-left: 20%;
    width: 80%;
    box-sizing: border-box; }

.dashboard-gutters {
    //gutters relative to the container
    margin: 5% 10%; }
