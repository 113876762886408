$light-grey: #d3d6d9;

.sideMenu-container {
    height: 100%;
    width: 20%;
    background-color: $light-grey;
    padding: 2em;
    position: fixed;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

.sideMenu-logo {
    width: 100%;
    //margin-bottom: 4vh
    flex: 1; }

.sideMenu-wrapper {
    margin: 5vh 0 !important; }

.sideMenu-label {
    margin-bottom: 1em; }

.sideMenu-filterWrapper {
    flex: 99; }

.sideMenu-buttonWrapper {
    //margin-top: 6vh
    flex: 1; }
