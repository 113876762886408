.login-container {
    flex: 1; }

.login-content {
    margin: 5em auto;
    max-width: 45%;
    padding: 3em 1em;
    text-align: center; }

.login-logo {
    width: 75px;
    margin: 1em auto 0px auto;
    display: block;
    align-content: center; }

.login-button {
    margin: 0px auto !important;
    margin-top: 120px !important;
    padding: 10px 20px !important; }

.login-procoreLogo {
    padding-top: 0px;
    width: 1.5em;
    height: 1.5em; }

.login-helpButton {
    margin: 15px auto 0px auto !important;
    display: block !important;
    align-content: center; }

@media only screen and (max-width: 600px) {
    .login-container {
        margin-top: 4em; }

    .login-content {
        margin: 0 auto;
        max-width: 100%;
        padding: auto; }

    .login-button {
        margin-top: 30% !important; } }


