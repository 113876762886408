$dark-blue: #022B3A;

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  color: $dark-blue;
  //keeps the scrollbar even when datepicker modal opens (stops moving elements)
  overflow: auto !important; }

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

#root {
  min-height: 100%;
  width: 100%;
  display: flex; }

input {
  color: $dark-blue; }
